import baseEditForm from "formiojs/components/_classes/component/Component.form";
import A8Utils from "../../utils/A8Utils";

export default (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        // You can ignore existing fields.
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        // Or add your own. The syntax is form.io component definitions.
                        type: "textfield",
                        input: true,
                        label: "My Custom Setting",
                        weight: 12,
                        key: "myCustomSetting" // This will be available as component.myCustomSetting
                    }
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Summary List',
                        key: 'invoiceSummaryList',
                        weight: -98,
                        components: [
                            {
                                label: 'Invoice Summary Label Name',
                                key: 'invoiceSummaryLabelName',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Summary Value Key',
                                key: 'invoiceSummaryValueKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Summary Field Type',
                                key: 'invoiceSummaryFieldType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Text',
                                            value: A8Utils.formBuilderFieldNames.text
                                        },
                                        {
                                            label: 'Input',
                                            value: A8Utils.formBuilderFieldNames.input
                                        },
                                        {
                                            label: 'Download',
                                            value: A8Utils.formBuilderFieldNames.download
                                        },
                                        {
                                            label: 'Upload',
                                            value: A8Utils.formBuilderFieldNames.upload
                                        },
                                        {
                                            label: 'Uploaded Invoice',
                                            value: 'uploadedInvoice'
                                        },
                                    ]
                                },
                            }
                        ]
                    }
                ]
            },
            {
                key: "validation",
                components: [
                ]
            },
            {
                key: "api",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Invoice Summary Api Url",
                        weight: 1,
                        key: "invoiceSummaryApiUrl"
                    },
                    {
                        type: "select",
                        input: true,
                        label: "Invoice Summary Api Url Method",
                        weight: 1,
                        key: "invoiceSummaryApiUrlMethod",
                        data: {
                            values: [
                                {
                                    label: 'GET',
                                    value: 'get'
                                },
                                {
                                    label: 'POST',
                                    value: 'post'
                                },
                                {
                                    label: 'PUT',
                                    value: 'put'
                                },
                                {
                                    label: 'PATCH',
                                    value: 'patch'
                                },
                                {
                                    label: 'DELETE',
                                    value: 'delete'
                                },
                            ]
                        },
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Summary Api Url Parameters List',
                        key: 'invoiceSummaryApiUrlParamsList',
                        weight: 2,
                        components: [
                            {
                                label: 'Invoice Summary Api Url Parameter',
                                key: 'invoiceSummaryApiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Summary Api Url Target Key',
                                key: 'invoiceSummaryApiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Summary Api Url Target',
                                key: 'invoiceSummaryApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: A8Utils.formBuilderFieldNames.submission
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Invoice Summary Api Response Data Key ",
                        weight: 3,
                        key: "invoiceSummaryApiResponseDataKey"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Invoice Download Api Url",
                        weight: 4,
                        key: "invoiceDownloadApiUrl"
                    },
                    {
                        type: "select",
                        input: true,
                        label: "Invoice Download Api Url Method",
                        weight: 4,
                        key: "invoiceDownloadApiUrlMethod",
                        data: {
                            values: [
                                {
                                    label: 'GET',
                                    value: A8Utils.formBuilderFieldNames.get
                                },
                                {
                                    label: 'POST',
                                    value: A8Utils.formBuilderFieldNames.post
                                },
                                {
                                    label: 'PUT',
                                    value: A8Utils.formBuilderFieldNames.put
                                },
                                {
                                    label: 'PATCH',
                                    value: A8Utils.formBuilderFieldNames.patch
                                },
                                {
                                    label: 'DELETE',
                                    value: A8Utils.formBuilderFieldNames.deleteMethod
                                },
                            ]
                        },
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Download Api Url Parameters List',
                        key: 'invoiceDownloadApiUrlParamsList',
                        weight: 5,
                        components: [
                            {
                                label: 'Invoice Download Api Url Parameter',
                                key: 'invoiceDownloadApiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Download Api Url Target Key',
                                key: 'invoiceDownloadApiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Download Api Url Target',
                                key: 'invoiceDownloadApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: A8Utils.formBuilderFieldNames.submission
                                        },
                                        {
                                            label: 'Invoice Data',
                                            value: A8Utils.formBuilderFieldNames.invoiceData
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Download Api Url Payload Parameters List',
                        key: 'invoiceDownloadApiUrlPayloadParamsList',
                        weight: 6,
                        components: [
                            {
                                label: 'Invoice Download Api Url Payload Parameter',
                                key: 'invoiceDownloadApiUrlPayloadParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Download Api Url Payload Target Key',
                                key: 'invoiceDownloadApiUrlPayloadTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Download Api Url Target',
                                key: 'invoiceDownloadApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: A8Utils.formBuilderFieldNames.submission
                                        },
                                        {
                                            label: 'Form Data',
                                            value: A8Utils.formBuilderFieldNames.formData
                                        },
                                        {
                                            label: 'Invoice Data',
                                            value: A8Utils.formBuilderFieldNames.invoiceData
                                        },
                                        {
                                            label: 'True',
                                            value: A8Utils.formBuilderFieldNames.True
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Invoice Upload Api Url",
                        weight: 7,
                        key: "invoiceUploadApiUrl"
                    },
                    {
                        type: "select",
                        input: true,
                        label: "Invoice Upload Api Url Method",
                        weight: 8,
                        key: "invoiceUploadApiUrlMethod",
                        data: {
                            values: [
                                {
                                    label: 'GET',
                                    value: 'get'
                                },
                                {
                                    label: 'POST',
                                    value: 'post'
                                },
                                {
                                    label: 'PUT',
                                    value: 'put'
                                },
                                {
                                    label: 'PATCH',
                                    value: 'patch'
                                },
                                {
                                    label: 'DELETE',
                                    value: 'delete'
                                },
                            ]
                        },
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Upload Api Url Parameters List',
                        key: 'invoiceUploadApiUrlParamsList',
                        weight: 9,
                        components: [
                            {
                                label: 'Invoice Upload Api Url Parameter',
                                key: 'invoiceUploadApiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Upload Api Url Target Key',
                                key: 'invoiceUploadApiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Upload Api Url Target',
                                key: 'invoiceUploadApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: A8Utils.formBuilderFieldNames.submission
                                        },
                                        {
                                            label: 'Invoice Data',
                                            value: A8Utils.formBuilderFieldNames.invoiceData
                                        },
                                        {
                                            label: 'Form Data',
                                            value: A8Utils.formBuilderFieldNames.formData
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Invoice Upload Api Url Payload Parameters List',
                        key: 'invoiceUploadApiUrlPayloadParamsList',
                        weight: 10,
                        components: [
                            {
                                label: 'Invoice Upload Api Url Payload Parameter',
                                key: 'invoiceUploadApiUrlPayloadParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Upload Api Url Payload Target Key',
                                key: 'invoiceUploadApiUrlPayloadTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Invoice Upload Api Url Target',
                                key: 'invoiceUploadApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: A8Utils.formBuilderFieldNames.submission
                                        },
                                        {
                                            label: 'Form Data',
                                            value: A8Utils.formBuilderFieldNames.formData
                                        },
                                        {
                                            label: 'Invoice Data',
                                            value: A8Utils.formBuilderFieldNames.invoiceData
                                        },
                                    ]
                                },
                            }
                        ]
                    }
                ]
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

const submission = 'submission';
const formData = 'formData';
const invoiceData = 'invoiceData';
const True = true;
const text = 'text';
const input = 'input';
const select = 'select';
const download = 'download'
const upload = 'upload'
const primary = 'primary'
const danger = 'danger'
const currency = 'currency';
const date = 'date';
const label = 'label';
const value = 'value';
const post = 'post';
const put = 'put';
const get = 'get';
const patch = 'patch';
const deleteMethod = 'delete';

const formBuilderFieldNames = {
    submission,
    formData,
    invoiceData,
    True,
    text,
    input,
    download,
    upload,
    primary,
    danger,
    currency,
    date,
    label,
    value,
    select,
    get,
    post,
    put,
    patch,
    deleteMethod
}

export { formBuilderFieldNames }
// Package Imports
import { Timeline } from 'antd';
import React, { useState, useEffect } from 'react'

// Project Imports
import A8Utils from '../../../utils/A8Utils';
import { timelinePreliminaryData } from '../CeqGridData';

const GridTimelineDummy = (props) => {

  const { component, submissionData, showTimeLineComponent } = props;

  const [timeline, setTimeline] = useState([])
  const [timeLineData, setTimeLineData] = useState([])
  console.log("tm>>>timelineColumnList:::", component?.timelineColumnList)

  useEffect(() => {
    if (showTimeLineComponent) {
      showTimeLineApiCallHandler()
    }
  }, [showTimeLineComponent])

  function getFormatedChildrenItemValue(dataItem, columnItem) {
    const value = A8Utils.getColumnElementValue(dataItem, columnItem.timelineColumnKey, "string");
    switch (columnItem.columnValueType) {
      case A8Utils.formBuilderFieldNames.date:
        return A8Utils.formatDate(value);
      case A8Utils.formBuilderFieldNames.currency:
        return A8Utils.formatCurrency(value);
      default:
        return value;
    }
  }

  function getTimeLineColumnList() {
    let list = timelinePreliminaryData;
    if (component?.timelineColumnList.length === 1 && Object.values(component?.timelineColumnList[0]).every(value => value === "")) {
      list = timelinePreliminaryData
    } else {
      list = [...list, ...component?.timelineColumnList]
    }
    return list;
  }

  function makeTimeline(data) {
    let timelineDataModel = []
    const timeLineColumnList = getTimeLineColumnList()

    data?.forEach((dataItem) => {
      let timelineElement = {}
      let children = []
      timeLineColumnList.forEach((columnItem) => {
        if (columnItem.timelineElementType === A8Utils.formBuilderFieldNames.label) {
          timelineElement["label"] = A8Utils.formatDate(A8Utils.getColumnElementValue(dataItem, columnItem.timelineColumnKey, "string"))
        } else if (columnItem.timelineElementType === A8Utils.formBuilderFieldNames.value) {
          let childrenItem = {}
          childrenItem[columnItem.timelineColumnName] = getFormatedChildrenItemValue(dataItem, columnItem)
          children.push(childrenItem)
        }
      })
      timelineElement["children"] = children
      timelineDataModel.push(timelineElement)
    })

    setTimeline(timelineDataModel)
  }

  console.log("tm>>>TIMELINE:::", timeline)

  function makeTimelineItems(data) {
    return data.map((item, key) => ({
      label: item.label,
      children: (
        <>
          {
            item.children.map((childrenItem, index) => {
              return (
                <p key={index}>
                  <span>{Object.keys(childrenItem)[0]}{" - "}</span>
                  <span>{Object.values(childrenItem)[0]}</span>
                </p>
              )
            })
          }
        </>
      )
    }))
  }

  function showTimeLineApiCallHandler() {
    let apiUrlParams = {}
    component.timelineApiUrlParamsList?.forEach((item) => {
      apiUrlParams[item.timelineApiUrlParameter] = submissionData[item.timelineApiUrlTargetKey]
    })

    if (component.timelineUrl) {
      let url = A8Utils.getAPiUrl(component.timelineUrl, apiUrlParams)

      const token = localStorage.getItem('token');
      const tokenType = localStorage.getItem('tokenType')
      const modifiedConfig = {
        headers: {
          'Content-Type': 'application/json',
          ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
        },
      };

      fetch(url, {
        headers: modifiedConfig?.headers,
      })
        .then((res) => res.json())
        .then((json) => {
          let data = component.timelineResponseDataKey === "" ? json : A8Utils.getColumnElementValue(json, component.timelineResponseDataKey, "object")
          setTimeLineData(data)
          makeTimeline(data)
        })
        .catch((error) => {
        })
    }
  }

  return (
    <Timeline
      mode={"left"}
      items={makeTimelineItems(timeline)}
    />
  )
}

export default GridTimelineDummy
import ContractGrid from "./ContractGrid";
import GridActionButtonsGroup from "./GridActionButtonsGroup";
import GridSummaryCard from "./GridSummaryCard";
import GridInvoiceSummary from "./GridInvoiceSummary"
import ExpandableContractGrid from "./ExpandableContractGrid";
import CustomObservable from '../utils/CustomObservable'
export const FormioCustomComponent = {
    ContractGrid,
    GridSummaryCard,
    GridInvoiceSummary,
    GridActionButtonsGroup,
    ExpandableContractGrid
}
export default FormioCustomComponent;


if(!window.A8FormIoDataObserver){
    window.A8FormIoDataObserver= new  CustomObservable()
}
import { Observable, Subject } from 'rxjs';

export default class CustomObservable {
  _value;
  currentValue;
  invoiceUploaded = false;
  constructor() {
    this._value = new Subject();
  }
  get value() {
    return this._value.asObservable();
  }

  set value(newData) {
    this.currentValue = newData;
    this.invoiceUploaded = newData;
    this._value.next(newData);
  }
}
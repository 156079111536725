const columnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'Contract',
        columnKey: 'contract'
    },
    {
        columnName: 'Customer Name',
        columnKey: 'customerName'
    },
    {
        columnName: 'Elligible Amount',
        columnKey: 'pos'
    },
    {
        columnName: 'Payout Amount',
        columnKey: 'totalPayout'
    },
    {
        columnName: 'Adjustment',
        columnKey: 'adjustmentAmount'
    },
];

const columnData = [
    {
        "id": 1,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 7606978.0,
        "totalPayout": 54648.59,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 60,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }, {
        "id": 2,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 1.0580258E7,
        "totalPayout": 76057.57,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 61,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }
]

const invoiceColumnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'System Invoice',
        columnKey: 'systemInvoice'
    },
    {
        columnName: 'Agency Invoice',
        columnKey: 'agencyInvoice'
    },
    {
        columnName: 'Amount',
        columnKey: 'amount'
    },
    {
        columnName: 'State',
        columnKey: 'state'
    },
    {
        columnName: 'Invoice Date',
        columnKey: 'invoiceDate'
    },
    {
        columnName: 'Payout Month',
        columnKey: 'payoutMonth'
    },
    {
        columnName: 'UTR Number',
        columnKey: 'utrNumber'
    },
    {
        columnName: 'Payment Amount',
        columnKey: 'paymentAmount'
    },
    {
        columnName: 'Payment Date',
        columnKey: 'paymentDate'
    },
    {
        columnName: 'Download Invoice',
        columnKey: 'downloadInvoice'
    },
    {
        columnName: 'Upload Invoice',
        columnKey: 'uploadInvoice'
    },
    {
        columnName: 'Uploaded Invoice',
        columnKey: 'uploadedInvoice'
    },
];

const invoiceColumnData = [
    {
        "systemInvoice": "ABCD/1234/DE56",
        "agencyInvoice": "",
        "amount": 2400,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2450,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "EFGH/5678/AB90",
        "agencyInvoice": "999111",
        "amount": 4000,
        "state": "Telangan",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "May",
        "utrNumber": "147298",
        "paymentAmount": 4500,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "ABCD/1234",
        "agencyInvoice": "",
        "amount": 2420,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2550,
        "paymentDate": "2024-04-30 12:09:20",
    },
]

const treeData = {
    "System": "B",
    "EMI": 14409,
    "Sales_Office": "20601",
    "Branch": "B",
    "Cluster": "MAHARASHTRA",
    "Cluster_Main": "MAHARASHTRA",
    "Region": "WEST",
    "Metro_Non_Metro": "NM",
    "REVISED_LOB": "HE",
    "WRTOFF_NPA_FLAG": "N",
    "CIF_No": "1",
    "Principal_OP": 761893.23,
    "Charges": 0,
    "Op_Irregularity_OD": 49.23,
    "Irregularity_OD": 0,
    "Billing_Date": 9,
    "Op_DPD": 0,
    "Op_Bkt": 0,
    "Bkt_OP_SEG": 0,
    "Clg_DPD": 0,
    "Clg_Bkt": 0,
    "Bkt_Status_Clg_For_billing": "NORM",
    "RISK_BKT_OP": "1",
    "POS_Category": "> 5 LACS POS",
    "Total_Amount_Collected": 15288,
    "CBC_Additional_Int": 700,
    "No_of_EMI": 1,
    "Total_EMI_No_of_Emi": 879,
    "Final_Penal_Charges": 0,
    "Late_Payment_Charges__Add_Int": 199.42,
    "To_be_Consider_for_Billing": "Y",
    "Disposal_Flag": "N",
    "Allocation_Date": "20240601",
    "Spocto_Billing_X_bkt": "N",
    "TECH_NON_TECH": "NON TECH",
    "Banking_Status": "BOUNCED",
    "First_Trail_Date": "20240602",
    "No_of_total_Trails": 4,
    "Disputed_Trails": "N",
    "Settlement_Closure_Disposal_Flag": "N",
    "POS_Loss_Flag": "N",
    "NACH_Flag": "N",
    "Delay_Deposition_Flag": "N",
    "Warrant_Execution_Flag": "N",
    "Charges_Collected_Flag": "Y",
    "Penal_Collected_Flag": "Y",
    "Restructure_1": "N",
    "Restructure_2": "N",
    "Market": "N",
    "Milestone_1": "N",
    "Milestone_2": "EM",
    "Payment_Mode": "Digital",
    "Type_of_Payment": "Partial",
    "Ref_1": "19808",
    "Ref_2": "N",
    "Ref_3": "1",
    "Allocation": "X BKT",
    "Sub_Allocation": "AGENCY",
    "CM_Name": "SAXXXXXXXXXXXXXSA",
    "CM_Code": "C123124",
    "Agency_Name": "HEXXXXXXXXXXXXXXOM",
    "Agency_Code": "A19808",
    "TL_Name": "IBXXXXXXXXXXKH",
    "TL_Code": "T19808056",
    "CRE_Name": "CHXXXXXXXXXXXXXDE",
    "CRE_E_code": "C19808104",
    "Account_No": "A9544022",
    "id": 55206,
    "created_at": "2024-10-04T02:20:42.000Z",
    "batch_id": 296,
    "transaction_date": "2024-05-31T18:30:00.000Z",
    "PV_pos_Efficiency": null,
    "PV_count_Efficiency": 0.951492537313433,
    "PV_lpc_Collection": null,
    "PV_Penal_Collection_Efficiency": null,
    "applicableGrids": [
        {
            "rule_plan": "HL Collection Rule Plan",
            "name": "HLC Payout Group",
            "rules": [
                {
                    "unique_id": "066eaa6a-378f-7725-8000-6f6cb7b38122",
                    "dmnDecisionId": "Collection_X-Agency-Performance-Collection-Efficiency",
                    "variable": "Grid Performance Payout",
                    "version": 1,
                    "finalOutputVariable": "Performance Payout"
                }
            ]
        }
    ],
    "serial": 6323,
    "rowsCount": "9344",
    "Agency Code": "A19808",
    "State": "MAHARASHTRA",
    "composite_key": "A19808:_:MAHARASHTRA",
    "composite_key_order": "Agency Code:_:State",
    "Performance Payout": 0,
    "Grid Performance Payout": "0",
    "LPC Payout": null,
    "Grid LPC Payout": null,
    "Delay Penalty": 0,
    "Grid DD Penalty": null,
    "Final Payout": 0,
    "adjustment_amount": null,
    "unified_id": "066ea97f-ab59-701a-8000-7dc3ef8067e7",
    "payment_process_id": "0670686d-e7a3-70b3-8000-5fdf254f19a9",
    "iprogram_id": "066ea86d-ad3c-77bc-8000-06b72cb1c1cc",
    "frequency_id": "066dfd6b-e0b2-7faa-8000-be346b127758",
    "frequency": "monthly",
    "start_date": "2024-06-01",
    "end_date": "2024-06-30",
    "organization_id": "066ea86a-ebe0-7a72-8000-78b0b30d6e2f",
    "calculation_details_id": "0670686d-e7c2-776d-8000-081ad20282a3",
    "final_output_variable_name": "Final Payout",
    "decisionOutputs": [
        {
            "decisionId": "Collection_X-Agency-Performance-Collection-Efficiency",
            "decisionName": "Collection_X-Agency-Performance-Collection-Efficiency",
            "decisionVersion": 1,
            "decisionOutput": "0",
            "ruleIndex": 43,
            "decisionInputs": [
                {
                    "PV_pos_Efficiency": "null"
                },
                {
                    "PV_count_Efficiency": "0.951492537313433"
                },
                {
                    "Bkt_Status_Clg_For_billing": "\"NORM\""
                },
                {
                    "REVISED_LOB": "\"HE\""
                },
                {
                    "Total_Amount_Collected": "15288"
                }
            ],
            "rulePlan": "HL Collection Rule Plan",
            "decisionVariable": "Performance Payout",
            "decisionOutputVariables": [
                {
                    "outputId": "OutputClause_10bv6ad",
                    "outputName": "Grid Performance Payout",
                    "outputValue": "0"
                }
            ],
            "feel_expression_formula": {
                "formula": "Grid Performance Payout",
                "output": 0,
                "variable": "Performance Payout",
                "inputs": [
                    {
                        "Grid Performance Payout": "0"
                    }
                ]
            }
        },
        {
            "decisionId": "NA",
            "decisionName": "NA",
            "decisionVersion": 0,
            "decisionOutput": 0,
            "ruleIndex": -1,
            "decisionInputs": [],
            "rulePlan": "HL Collection Rule Plan",
            "decisionVariable": "NA",
            // "decisionOutputVariables": [
            //     {
            //         "outputId": "OutputClause_10bv6ad",
            //         "outputName": "Grid Performance Payout",
            //         "outputValue": "0"
            //     }
            // ],
            "grid_applicability_formula": {
                "formula": "(Allocation = 'X BKT' AND Sub_Allocation = 'AGENCY')",
                "output": true,
                "inputs": [
                    {
                        "Allocation": "X BKT"
                    },
                    {
                        "Sub_Allocation": "AGENCY"
                    }
                ]
            },
            "ElseJsonLogic_formula": {
                "formula": "0",
                "output": 0,
                "variable": "Delay Penalty",
                "inputs": []
            }
        }
    ],
    "final_output_formula": {
        "formula": "(Performance Payout - Delay Penalty)",
        "output": 0,
        "inputs": [
            {
                "Delay Penalty": "0"
            },
            {
                "Performance Payout": "0"
            }
        ]
    }
}

export { columnList, columnData, invoiceColumnList, invoiceColumnData, treeData }
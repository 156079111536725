// Package Imports
import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Form } from '@formio/react';
import { useParams, useLocation } from 'react-router-dom';
import HyperDX from '@hyperdx/browser';

// Project Imports
import formiojs from '../../formio';
import FormUtils from '../../utils/FormUtils';
import { getEnvData } from "../../utils/getEnvData";
import EnvConstant from "../../constant/EnvConstant";
import ApiEndPoints from "../../constant/ApiEndPoints";
import { Images } from '../../constant/ImagePath';

// TODO : remove after testing complete

const TaskShow = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [formKey, setFormKey] = useState(null);
  const [formIOURL, setFormIOURL] = useState(null);
  const [formIoData, setFormIOData] = useState(null);


  const state = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param = searchParams.get("taskId");
  const authToken = localStorage.getItem("token")
  localStorage.setItem('tokenType', 'Bearer')
  

  useEffect(() => {
    if (param && authToken) {

      HyperDX.init({
        apiKey: '1cb83682-06a1-466f-92ba-f76bb57f27ad',
        service: 'http://form-renderer.augment8.dev/',
        tracePropagationTargets: [ `${getEnvData(EnvConstant.API_PAYOUT_URL)}${ApiEndPoints.GET_TASK_INSTANCE}/?unique_id=${param}`],
        consoleCapture: true, 
        advancedNetworkCapture: true, 
      });

      fetch(
        `${getEnvData(EnvConstant.API_PAYOUT_URL)}${ApiEndPoints.GET_TASK_INSTANCE}/?unique_id=${param}`,
        {
          headers: {
            authorization: localStorage.getItem("tokenType") + " " + authToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });

        HyperDX.addAction('API-Response', {
          formId: data ? data[0] : "No data",
        });
    }
  }, [param, authToken]);

 
  

  console.log(data, 'data');

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      const key = data.formKey;
      const url = `${FormUtils.getFormUrlByPath(key)}`;
      // eslint-disable-next-line new-cap
      const formio = new formiojs(url);
      const result = formio.loadForm();
      result.then((form) => {
        setFormIOData(form);
      });

      setFormKey(key);
      setFormIOURL(url);
    }
  }, [data]);

  
  console.log('Form IO Data', formIoData);
  console.log(data, formKey, formIOURL, id, 'data');
  return (
    
      <div>
      
        { Object.keys(data).length !== 0 ? 
          <Form
            form={formIoData}
            onSubmit={(submissionData) => {
              console.log("submissionData", submissionData)
            }}
            submission={{
              data: { oid: uniqueId('oid'), ...data, formIoData },
            }}
          />
          : 
          <div>
            <img className='form-loader' alt="Loading" src={Images.Loader}></img>
          </div> 
        }
      </div>
  );
};

export default TaskShow;

// Package Imports
import React, { Component, useEffect } from "react";
import { Flex, Spin } from "antd";
import { useState } from "react";
import { Formio, ReactComponent } from "react-formio";
import { useDebouncedCallback } from 'use-debounce';
import Input from "antd/es/input/Input";

// Project Imports
import { columnList, columnData } from "../CeqGridData"
import GridTimeline from "./GridTimeline";
import getAPiUrl from "../../../utils/A8Utils/Methods/getApiUrl";
import getColumnElementValue from "../../../utils/A8Utils/Methods/getColumnElementValue";
import GridDownloadButton from "./GridDownloadButton";
import GridShowTimelineButton from "./GridShowTimelineButton";
import GridAdjustmentDrawer from "./GridAdjustmentDrawer";
import A8Utils from "../../../utils/A8Utils";
import ContractGridTable from "./ContractGridTable";
import "../index.css"
import GridTimelineDummy from "./GridTimelineDummy";

const ContractGridCustomComp = (props) => {

    const { component, value, isBuilder } = props;
    let debouncedApiCallHandler = useDebouncedCallback(configApiCallHandler, 100);

    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columnDataResponse, setColumnDataResponse] = useState([])
    const [gridColumnList, setGridColumnList] = useState([])
    const [adjustmentClickedRowData, setAdjustmentClickedRowData] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [showTimeLineComponent, setShowTimeLineComponent] = useState(false)
    const [paginationCurrentPage, setPaginationCurrentPage] = useState(1)
    const [paginationPageLimit, setPaginationPageLimit] = useState(10)
    const [totalPage, setTotalPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(null)
    const [paginationData, setPaginationData] = useState({
        keyWord: "",
        currentPage: 1,
        pageLimit: 10,
    })

    // Form Field Component Keys
    let keyForId = component.ceqGridId;
    let formDataGridFields = {
        apiUrl: component.apiUrl,
        apiUrlParamsList: component.apiUrlParamsList,
        responseDataKey: component.responseDataKey,
        showPagination: component.showPagination,
    }
    let formPaginationFields = {
        pageNumberKey: component.pageNumberKey,
        limitKey: component.limitKey,
        responseTotalKey: component.responseTotalKey,
        searchKeyWordKey: component.searchKeyWordKey,
    }
    let formAddAdjustmentFields = {
        addAdjustmentUrl: component.addAdjustmentUrl,
        addAdjustmentApiUrlParamsList: component.addAdjustmentApiUrlParamsList,
        addAdjustmentApiUrlPayloadParamsList: component.addAdjustmentApiUrlPayloadParamsList,
        reasonsFieldApiUrl: component.reasonsFieldApiUrl,
        reasonsFieldApiUrlParamsList: component.reasonsFieldApiUrlParamsList,
        reasonsFieldApiResponseDataKey: component.reasonsFieldApiResponseDataKey,
    }
    let formDisputeComponents = {
        showDispute: component.showDispute,
        disputeKey: component.disputeKey,
    }

    useEffect(() => {
        setGridColumnList(component?.columnList || [])
        if (isBuilder) {
            setColumnDataResponse(columnData)
        }
    }, [])

    useEffect(() => {
        debouncedApiCallHandler()
        setModalShow(false)
    }, [])

    const mapResponseData = (data, responseDataKey, responseTotalKey) => {
        try {
            let gridDataArray = data
            let totalRecords = data

            let dataKeyArray = responseDataKey.split(".")
            let totalKeyArray = responseTotalKey.split(".")

            dataKeyArray.forEach((item) => {
                gridDataArray = gridDataArray[item]
            })
            totalKeyArray.forEach((item) => {
                totalRecords = totalRecords[item]
            })

            return { gridDataArray, totalRecords }
        } catch (error) {
            console.log("error:::::", error)
        }
    }

    function configApiCallHandler() {
        if (component.dataTableConfigApiUrl) {
            configApiCall()
        }else{
            apiCallHandler()
        }
    }

    function apiCallHandler() {
        if (isBuilder) {
            return
        }
        if (value) {

            let apiUrlParams = {}
            formDataGridFields.apiUrlParamsList?.forEach((item) => {
                apiUrlParams[item.apiUrlParameter] = A8Utils.getColumnElementValue(value, item.apiUrlTargetKey)
            })

            let paginationParams = {}
            paginationParams[formPaginationFields.pageNumberKey] = paginationData?.currentPage;
            paginationParams[formPaginationFields.limitKey] = paginationData?.pageLimit;
            if (paginationData?.keyWord.length > 0) {
                paginationParams[formPaginationFields.searchKeyWordKey] = paginationData?.keyWord;
            }

            if (formDataGridFields.apiUrl) {
                
                let url = getAPiUrl(formDataGridFields.apiUrl, apiUrlParams, paginationParams)
                const token = localStorage.getItem('token');
                const tokenType = localStorage.getItem('tokenType')
                const modifiedConfig = {
                    headers: {
                      'Content-Type': 'application/json',
                      ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                  };
                setLoading(true)
                fetch(url, {
                    headers: modifiedConfig?.headers
                })
                    .then((res) =>  res.json())
                    .then((json) => {
                        setLoading(false)
                        let mappedResponse = mapResponseData(json, formDataGridFields.responseDataKey, formPaginationFields.responseTotalKey)
                        let totalPage = Math.ceil(mappedResponse.totalRecords / paginationPageLimit)
                        setColumnDataResponse(mappedResponse.gridDataArray)
                        setTotalPage(totalPage)
                        setTotalRecords(mappedResponse.totalRecords)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            }
        }
    }

    function configApiCall() {
        if (value) {
            let configApiUrlParams = {}
            component.dataTableConfigApiUrlParamsList?.forEach((item) => {
                configApiUrlParams[item.datatTableConfigApiUrlParameter] = A8Utils.getColumnElementValue(value, item.dataTableConfigApiUrlTargetKey)
            })

            if (component.dataTableConfigApiUrl) {
                let url = getAPiUrl(component.dataTableConfigApiUrl,configApiUrlParams)
                const token = localStorage.getItem('token');
                const tokenType = localStorage.getItem('tokenType')
                const modifiedConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                };
                fetch(url, {
                    headers: modifiedConfig?.headers
                })
                    .then((res) => res.json())
                    .then((json) => {
                        handleTabelHeaderSource(json)
                    })
                    .catch((error) => {
                    })
            }
        }
    }

    function handleTabelHeaderSource(data) {
        const configFlag = getColumnElementValue(data, component.configHeaderFlagKey)
        if (configFlag) {
            const headers = getColumnElementValue(data, component.dataTableConfigApiResponseDataKey, 'object')
            setGridColumnList(headers)
        } else {
            setGridColumnList(component?.columnList || [])
        }
        apiCallHandler()
    }

    useEffect(() => {
        debouncedApiCallHandler()
    }, [paginationData])

    const onPaginationDataChange = (data) => {
        setPaginationCurrentPage(data?.currentPage)
        setPaginationPageLimit(data?.pageLimit)
        setPaginationData({ ...paginationData, ...data })
    }

    const onAdjustmentClick = (dataElement) => {
        setModalShow(true)
        setAdjustmentClickedRowData(dataElement)
    }

    useEffect(() => {
        let state = true;
        if (isBuilder) {
            state = true;
        }
        if (value['oid']) {
            state = true;
        }
        setReady(state)
        console.log('value  useEffect', state, value['oid'])
    }, [value['oid']])

    if (!ready) {
        return <>Loadding</>
    }

    return (
        <div className="grid-component-wrapper">
            <Flex justify={'space-between'} align={"center"} className="input-buttons-wrapper" >
                <div>
                    {!showTimeLineComponent &&
                        <>
                            <div className='field-floating-label'>
                                <label className="control-label">{A8Utils.labels.customerName}</label>
                                <Input
                                    size="large"
                                    type={"text"}
                                    placeholder={A8Utils.labels.search}
                                    onChange={(e) => {
                                        onPaginationDataChange({ keyWord: e.target.value })
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
                <Flex justify={'space-between'} align={"center"} gap={"small"}>
                    <div>

                        {
                            component.showDownloadExcel &&
                            <GridDownloadButton
                                component={component}
                                submissionData={value}
                            />
                        }
                    </div>
                    <div>
                        {
                            component.showTimeline &&
                            <GridShowTimelineButton
                                setShowTimeLineComponent={setShowTimeLineComponent}
                                showTimeLineComponent={showTimeLineComponent}
                            />
                        }
                    </div>
                </Flex>
            </Flex>
            {!showTimeLineComponent &&
                <div>
                    <Spin spinning={loading}> 
                        <ContractGridTable
                            gridColumnList={gridColumnList}
                            columnDataResponse={columnDataResponse}
                            onAdjustmentClick={onAdjustmentClick}
                            onPaginationDataChange={onPaginationDataChange}
                            total={totalRecords}
                        />
                    </Spin>
                    <GridAdjustmentDrawer
                        visible={modalShow}
                        onOk={() => setModalShow(false)}
                        onCancel={() => setModalShow(false)}
                        data={adjustmentClickedRowData}
                        addAdjustmentApiUrlParamsList={formAddAdjustmentFields.addAdjustmentApiUrlParamsList}
                        addAdjustmentUrl={formAddAdjustmentFields.addAdjustmentUrl}
                        submissionData={value}
                        addAdjustmentApiUrlPayloadParamsList={formAddAdjustmentFields.addAdjustmentApiUrlPayloadParamsList}
                        reasonsFieldApiUrl={formAddAdjustmentFields.reasonsFieldApiUrl}
                        reasonsFieldApiUrlParamsList={formAddAdjustmentFields.reasonsFieldApiUrlParamsList}
                        reasonsFieldApiResponseDataKey={formAddAdjustmentFields.reasonsFieldApiResponseDataKey}
                    />
                </div>
            }
            {
                showTimeLineComponent &&
                // <GridTimeline
                //     component={component}
                //     submissionData={value}
                //     showTimeLineComponent={showTimeLineComponent}
                // />
                <GridTimelineDummy
                    component={component}
                    submissionData={value}
                    showTimeLineComponent={showTimeLineComponent}
                />
            }
        </div>
    );
};

export default ContractGridCustomComp;
const getColumnElementValue = (allData, columnKey, type = "string") => {
    if (!columnKey) return "";

    let colVal = columnKey.includes(".") ?
        columnKey.split(".").reduce((value, param) => value ? value[param] : null, allData) :
        allData[columnKey];

    if (type === "string") {
        if (typeof colVal === "object") {
            colVal = JSON.stringify(colVal);
        } else if (typeof colVal === 'boolean') {
            return colVal
        } else {
            colVal = colVal ? colVal.toString() : "";
        }
    }

    return colVal;
};

export default getColumnElementValue;
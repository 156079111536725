import baseEditForm from "formiojs/components/_classes/component/Component.form";
import A8Utils from "../../utils/A8Utils";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "My Custom Setting",
            weight: 12,
            key: "myCustomSetting" // This will be available as component.myCustomSetting
          },
          {
            label: 'Show Download Details',
            key: 'showDownloadExcel',
            input: true,
            type: 'checkbox',
            weight: 100,
          },
          {
            label: 'Show Timeline',
            key: 'showTimeline',
            input: true,
            type: 'checkbox',
            weight: 101,
          },
          {
            label: 'Show Dispute',
            key: 'showDispute',
            input: true,
            type: 'checkbox',
            weight: 102,
          },
          {
            label: 'Show Pagination',
            key: 'showPagination',
            input: true,
            type: 'checkbox',
            weight: 102,
          },
          {
            label: 'Show Invoice',
            key: 'showInvoice',
            input: true,
            type: 'checkbox',
            weight: 102,
          },
          {
            type: "textfield",
            input: true,
            label: "Dispute Key",
            key: "disputeKey",
            weight: 103,
          },
        ]
      },
      {
        key: "data",
        components: [
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Ceq Grid Id",
            weight: 12,
            key: "ceqGridId" // This will be available as component.myCustomSetting
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Column List',
            key: 'columnList',
            weight: -100,
            components: [
              {
                label: 'Column Name',
                key: 'columnName',
                input: true,
                type: 'textfield'
              },
              {
                label: 'Column Key',
                key: 'columnKey',
                input: true,
                type: 'textfield'
              },
              {
                label: 'Adjustment',
                key: 'isAdjustment',
                input: true,
                type: 'checkbox'
              },
              {
                label: 'Column Value Type',
                key: 'columnValueType',
                input: true,
                type: 'select',
                data: {
                  values: [
                    {
                      label: 'Currency',
                      value: A8Utils.formBuilderFieldNames.currency
                    },
                    {
                      label: 'Text',
                      value: A8Utils.formBuilderFieldNames.text
                    },
                    {
                      label: 'Date',
                      value: A8Utils.formBuilderFieldNames.date
                    },
                  ]
                },
              }
            ]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Timeline Column List',
            key: 'timelineColumnList',
            weight: -99,
            components: [
              {
                label: 'Timeline Column Name',
                key: 'timelineColumnName',
                input: true,
                type: 'textfield'
              },
              {
                label: 'Timeline Column Key',
                key: 'timelineColumnKey',
                input: true,
                type: 'textfield'
              },
              {
                label: 'Column Value Type',
                key: 'columnValueType',
                input: true,
                type: 'select',
                data: {
                  values: [
                    {
                      label: 'Currency',
                      value: A8Utils.formBuilderFieldNames.currency
                    },
                    {
                      label: 'Text',
                      value: A8Utils.formBuilderFieldNames.text
                    },
                    {
                      label: 'Date',
                      value: A8Utils.formBuilderFieldNames.date
                    },
                  ]
                },
              },
              {
                label: 'Timeline Element Type',
                key: 'timelineElementType',
                input: true,
                type: 'select',
                data: {
                  values: [
                    {
                      label: 'Label',
                      value: A8Utils.formBuilderFieldNames.label
                    },
                    {
                      label: 'Value',
                      value: A8Utils.formBuilderFieldNames.value
                    },
                  ]
                },
              }
            ]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Invoice Summary Column List',
            key: 'invoiceSummaryColumnList',
            weight: -98,
            components: [{
              label: 'Invoice Summary Column Name',
              key: 'invoiceSummaryColumnName',
              input: true,
              type: 'textfield'
            }, {
              label: 'Invoice Summary Column Key',
              key: 'invoiceSummaryColumnKey',
              input: true,
              type: 'textfield'
            }, {
              label: 'Invoice Summary Field Type',
              key: 'invoiceSummaryFieldType',
              input: true,
              type: 'select',
              data: {
                values: [
                  {
                    label: 'Text',
                    value: 'text'
                  },
                  {
                    label: 'Input',
                    value: 'input'
                  },
                  {
                    label: 'Download',
                    value: 'download'
                  },
                  {
                    label: 'Upload',
                    value: 'upload'
                  },
                  {
                    label: 'Uploaded Invoice',
                    value: 'uploadedInvoice'
                  },
                ]
              },
            }]
          }
        ]
      },
      {
        key: "validation",
        components: []
      },
      {
        key: "api",
        components: [
          {
            type: "textfield",
            input: true,
            label: "Data Table Config Api Url",
            weight: 0,
            key: "dataTableConfigApiUrl"
          },
          {
            type: "textfield",
            input: true,
            label: "Config Header Flag Key",
            weight: 0.2,
            key: "configHeaderFlagKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Data Table Config Api Response Data Key",
            weight: 0.3,
            key: "dataTableConfigApiResponseDataKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Api Url",
            weight: 1,
            key: "apiUrl"
          },
          {
            type: "textfield",
            input: true,
            label: "Download Url",
            weight: 8,
            key: "downloadUrl"
          },
          {
            type: "textfield",
            input: true,
            label: "Timeline Url",
            weight: 10,
            key: "timelineUrl"
          },
          {
            type: "textfield",
            input: true,
            label: "Page Number Key",
            weight: 3,
            key: "pageNumberKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Limit Key",
            weight: 4,
            key: "limitKey"
          },
          {
            type: "textfield",
            input: true,
            label: " Search Key-Word Key",
            weight: 5,
            key: "searchKeyWordKey"
          },
          {
            type: "textfield",
            input: true,
            label: " Response Data Key",
            weight: 6,
            key: "responseDataKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Response Total Key",
            weight: 7,
            key: "responseTotalKey"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Data Table Config Api Url Parameters List',
            key: 'dataTableConfigApiUrlParamsList',
            weight: 0.1,
            components: [{
              label: 'Data Table Config Api Url Parameter',
              key: 'datatTableConfigApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Data Table Config Api Url Target Key',
              key: 'dataTableConfigApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Data Table Config Api Url Target',
              key: 'dataTableConfigApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Api Url Parameters List',
            key: 'apiUrlParamsList',
            weight: 2,
            components: [{
              label: 'Api Url Parameter',
              key: 'apiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Api Url Target Key',
              key: 'apiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Api Url Target',
              key: 'apiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Download Api Url Parameters List',
            key: 'downloadApiUrlParamsList',
            weight: 9,
            components: [{
              label: 'Download Api Url Parameter',
              key: 'downloadApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Download Api Url Target Key',
              key: 'downloadApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Download Api Url Target',
              key: 'downloadApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Timeline Api Url Parameters List',
            key: 'timelineApiUrlParamsList',
            weight: 11,
            components: [{
              label: 'Timeline Api Url Parameter',
              key: 'timelineApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Timeline Api Url Target Key',
              key: 'timelineApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Timeline Api Url Target',
              key: 'timelineApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: "textfield",
            input: true,
            label: "Timeline Response Data Key",
            weight: 12,
            key: "timelineResponseDataKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Reasons Field Api Url",
            weight: 13,
            key: "reasonsFieldApiUrl"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Reasons Field Api Url Parameters List',
            key: 'reasonsFieldApiUrlParamsList',
            weight: 14,
            components: [{
              label: 'Reasons Field Api Url Parameter',
              key: 'reasonsFieldApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Reasons Field Api Url Target Key',
              key: 'reasonsFieldApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Reasons Field Api Url Target',
              key: 'reasonsFieldApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: "textfield",
            input: true,
            label: "Reasons Field Api Response Data Key",
            weight: 15,
            key: "reasonsFieldApiResponseDataKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Add Adjustment Url",
            weight: 16,
            key: "addAdjustmentUrl"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Add Adjustment Api Url Parameters List',
            key: 'addAdjustmentApiUrlParamsList',
            weight: 17,
            components: [{
              label: 'Add Adjustment Api Url Parameter',
              key: 'addAdjustmentApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Add Adjustment Api Url Target Key',
              key: 'addAdjustmentApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Add Adjustment Api Url Target',
              key: 'addAdjustmentApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Add Adjustment Api Url Payload Parameters List',
            key: 'addAdjustmentApiUrlPayloadParamsList',
            weight: 18,
            components: [{
              label: 'Add Adjustment Api Url Payload Parameter',
              key: 'addAdjustmentApiUrlPayloadParameter',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Add Adjustment Api Url Payload Target Key',
              key: 'addAdjustmentApiUrlPayloadTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Add Adjustment Api Url Target',
              key: 'addAdjustmentApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [
                  {
                    label: 'Submission',
                    value: 'submission'
                  },
                  {
                    label: 'Form Data',
                    value: 'formData'
                  },
                  {
                    label: 'Row Data',
                    value: 'rowData'
                  },
                ]
              },
            }]
          },
          {
            type: "textfield",
            input: true,
            label: "Invoice Api Url",
            weight: 19,
            key: "invoiceApiUrl"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Invoice Api Url Parameters List',
            key: 'invoiceApiUrlParamsList',
            weight: 20,
            components: [{
              label: 'Invoice Api Url Parameter',
              key: 'invoiceApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Invoice Api Url Target Key',
              key: 'invoiceApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Invoice Api Url Target',
              key: 'invoiceApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [{
                  label: 'Submission',
                  value: 'submission'
                },]
              },
            }]
          },
          {
            type: "textfield",
            input: true,
            label: "Invoice Api Url Response Data Key",
            weight: 21,
            key: "invoiceApiUrlResponseDataKey"
          },
          {
            type: "textfield",
            input: true,
            label: "Invoice Download Api Url",
            weight: 22,
            key: "invoiceDownloadApiUrl"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Invoice Download Api Url Parameters List',
            key: 'invoiceDownloadApiUrlParamsList',
            weight: 23,
            components: [{
              label: 'Invoice Download Api Url Parameter',
              key: 'invoiceDownloadApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Invoice Download Api Url Target Key',
              key: 'invoiceDownloadApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Invoice Download Api Url Target',
              key: 'invoiceDownloadApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [
                  {
                    label: 'Submission',
                    value: 'submission'
                  }, {
                    label: 'Row Data',
                    value: 'rowData'
                  }
                ]
              },
            }]
          },
          {
            type: "textfield",
            input: true,
            label: "Uploaded Invoice Download Api Url",
            weight: 24,
            key: "uploadedInvoiceDownloadApiUrl"
          },
          {
            type: 'datagrid',
            input: true,
            label: 'Uploaded Invoice Download Api Url Parameters List',
            key: 'uploadedInvoiceDownloadApiUrlParamsList',
            weight: 25,
            components: [{
              label: 'Uploaded Invoice Download Api Url Parameter',
              key: 'uploadedInvoiceDownloadApiUrlParameter',
              input: true,
              type: 'textfield'
            }, {
              label: 'Uploaded Invoice Download Api Url Target Key',
              key: 'uploadedInvoiceDownloadApiUrlTargetKey',
              input: true,
              type: 'textfield'
            },
            {
              label: 'Uploaded Invoice Download Api Url Target',
              key: 'uploadedInvoiceDownloadApiUrlTarget',
              input: true,
              type: 'select',
              data: {
                values: [
                  {
                    label: 'Submission',
                    value: 'submission'
                  }, {
                    label: 'Row Data',
                    value: 'rowData'
                  }
                ]
              },
            }]
          },
        ]
      },
      {
        key: "conditional",
        components: []
      },
      {
        key: "logic",
        components: []
      }
    ],
    ...extend
  );
};
